import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import "./pricing.css";

function PricingHome() {
  return (
    <div>
      <div className="flex justify-between bg-[#111827]">
        <div className="hidden sm:block w-full bg-[#111827]"></div>
        <div className="w-full sm:w-auto font-poppins sm:whitespace-nowrap flex justify-center bg-[#1e2c39]">
          <NavLink className="px-4 sm:px-6 py-2 text-xs xs:text-sm ms:text-lg text-center text-white" to={"itr"}>ITR Pricing</NavLink>
          <div className="my-1 w-[0.005rem] rounded-full bg-slate-700"></div>
          <NavLink className="px-4 sm:px-6 py-2 text-xs xs:text-sm ms:text-lg text-center text-white" to={"notices"}>Notices</NavLink>
          <div className="my-1 w-[0.010rem] rounded-full bg-slate-700"></div>
          <NavLink className="px-4 sm:px-6 py-2 text-xs xs:text-sm ms:text-lg text-center text-white" to={"tax"}>Tax Planning</NavLink>
        </div>
        <div className="hidden sm:block w-full bg-[#111827]"></div>
      </div>
      <Outlet />
    </div>
  );
}

export default PricingHome;
