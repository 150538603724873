import React, { useContext, useEffect, useState } from "react";
import land from "../../assets/tax-land.png";
import { Typewriter } from "react-simple-typewriter";
import { unauthorizedaxios } from "../../axiosinit";
import { BaseProvide } from "./Base";
import { AppProvide } from "../../App";
import { phoneValidate } from "../../phoneValidation";
import BrowserTitleName from "../BrowserTitleName";
import Services from "../services/Services";
import Feedback from "../feedback/Feedback";

function Home() {
  const Basecontext = useContext(BaseProvide);
  const Appcontext = useContext(AppProvide);
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  const sentences = [
    "Share traders",
    "Salaried taxpayers",
    "Capital Gains taxation",
    "Futures & options traders"
  ];

  const sendOTP = (email) => {
    unauthorizedaxios.post('/auth/client_verify', { email: email })
      .then((res) => {
        console.log(res)
      })
  }

  const submitPhoneNumber = e => {
    e.preventDefault();
    if (Basecontext.phone) {
      Appcontext.setIs_loading(true)
      unauthorizedaxios.post(`/auth/is_clientexists/phone=${Basecontext.phone}`)
        .then((res) => {
          console.log(res)
          if (JSON.parse(res.data['exist_status'])) {
            Appcontext.setDirectLogin(true)
            Basecontext.setEmail(res.data['email'])
            Basecontext.navigate("/otp-verify")
            sendOTP(res.data['email'])
            Appcontext.setIs_loading(false)
          }
          else {
            Basecontext.setClient_id(res.data['client_id'])
            Basecontext.setEmail('')
            Appcontext.setDirectLogin(false)
            Appcontext.setIs_loading(false)
            Basecontext.navigate("/signup")
          }
        })
        .catch((err) => {
          if (err) {
            Appcontext.setIs_loading(false)
          }
        })

    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    if (Basecontext.phone !== '') {
      setIsPhoneValid(phoneValidate(Basecontext.phone));
    }
  }, [Basecontext.phone]);


  return (
    <>
      <BrowserTitleName title="Home" />
      
      <div className="pt-10 bg-[#1e2c39]">
        <div className="px-5 sm:container sm:mx-auto">
          <div className="flex flex-col space-y-4 lg:space-y-0 lg:flex-row lg:justify-between">
            <div className="lg:w-[50%] flex flex-col justify-center">
              <div className="mb-4 font-roboto text-4xl text-yellow-200">Welcome to TaxSuvidhaa</div>
              <div className="text-4xl font-semibold text-white">
                <span>Assisted Tax Filing Platform For</span>
              </div>
              <div className="text-4xl font-semibold text-white">
                <Typewriter
                  words={sentences}
                  loop={5}
                  cursor
                  cursorStyle={<br />}
                  typeSpeed={50}
                  deleteSpeed={50}
                  delaySpeed={1000}
                />
              </div>
              <form onSubmit={submitPhoneNumber} className="mt-8 flex flex-col md:flex-row md:space-x-3 md:space-y-0 space-y-3 font-roboto text-xl md:text-lg">
                <div className="w-full md:w-[60%] rounded">
                  {
                    !isPhoneValid &&
                    <span className="mt-2 font-roboto font-medium text-red-500">Phone Number must be of 10 digits*</span>
                  }
                  <input value={Basecontext.phone} onChange={(e) => Basecontext.setPhone(e.target.value)} className="px-3 py-3 w-full rounded focus:outline-none text-red-500" type="text" name="" id="" placeholder="Enter your mobile number" />
                </div>
                <button style={{ cursor: (Basecontext.phone === '' || !isPhoneValid) ? "not-allowed" : "pointer" }} className="px-6 py-3 self-end rounded duration-200 text-white hover:bg-blue-500 bg-red-500" disabled={!isPhoneValid}>Start Filing</button>
              </form>
              {/* <h1 className="glow font-roboto">25% welcome bonus as a discount</h1> */}
            </div>
            <div className="flex justify-center">
              <img className="w-full" src={land} alt={"tax-land"} />
            </div>
          </div>
          <Services />

          {/* <div className="flex flex-col bg-red-400">
            <div className="text-4xl font-semibold text-white">
              <span>Assisted Tax Filing Platform For</span>
            </div>
            <div className="text-4xl font-semibold text-white">
              <Typewriter
                words={sentences}
                loop={5}
                cursor
                cursorStyle='|'
                typeSpeed={50}
                deleteSpeed={50}
                delaySpeed={1000}
                cursorBlinking={false}
              />
            </div>
            <div className="mt-8 flex flex-col md:flex-row md:space-x-3 md:space-y-0 space-y-3 font-roboto text-xl md:text-lg">
              <input className="px-3 py-3 w-full md:w-[30%] rounded focus:outline-none text-red-500" type="text" name="" id="" placeholder="Enter your mobile number" />
              <button className="px-6 py-3 self-end rounded duration-200 text-white hover:bg-blue-500 bg-red-500">Start Filing</button>
            </div>
          </div>
          <div className="bg-blue-400">
            <img src={land} alt={"tax-land"} />
          </div> */}
        </div>

        <Feedback />
      </div>
    </>
  );
}

export default Home;
