import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Calculator from './Calculator';
import HRA from './calculator/HRA';
import BrowserTitleName from "../BrowserTitleName";

function Calculators() {
  let id = 0;
  const navigate = useNavigate();

  const toggleCalculator = (id, maxHeight) => {
    const calculator = document.getElementsByClassName("calculators")[id];
    const calculatorComponent = calculator.lastElementChild;
    calculator.firstElementChild.lastElementChild.lastElementChild.lastElementChild.classList.toggle("rotate-90");

    if (calculatorComponent.clientHeight === 0) {
      calculatorComponent.style.height = maxHeight;
    } else {
      calculatorComponent.style.height = "0px";
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BrowserTitleName title="All Calculators" />

      <div className="pt-16 pb-20 md:px-32">
        <div className="mx-4 sm:container sm:mx-auto">
          <div className="font-roboto select-none flex items-center gap-2 mb-2">
            <span className="cursor-pointer text-blue-600 hover:text-blue-800" onClick={() => navigate('/')}>
              <i className="fa-solid fa-house"></i>
              <span className="ml-1">Home</span>
            </span>
            <span>/</span>
            <span title="Calculators"><i className="fa-solid fa-calculator text-green-600"></i></span>
          </div>
          <div className="flex flex-col rounded shadow-md shadow-slate-400 bg-white">
            <div className="font-roboto px-10 py-4 space-x-1 text-lg text-white rounded-t bg-gradient-to-r from-indigo-600 via-purple-500 to-pink-500">
              <span><i className="fa-solid fa-calculator"></i></span>
              <span className="font-semibold">All Calculators</span>
            </div>
            <div className="">
              <Calculator id={id++} name={"HRA"} title={"Calculate House Rent Allowance"} component={<HRA />} maxHeight={"70px"} toggleCalculator={toggleCalculator} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Calculators;
