import React,{useContext} from 'react'
import { AppProvide } from '../../App'

function CompleteSelectionCard() {
    const Appcontext = useContext(AppProvide);
  return (
    <>
              <div className="sm:container sm:mx-auto">
                <div className="p-10 md:mx-48 md:my-32 text-center rounded-md shadow-md shadow-slate-400 bg-white">
                  <div className="text-4xl font-semibold">{Appcontext.userInfo?.name?.toUpperCase()}</div>
                  <div className="mt-4 mb-3"><i className="fa-solid fa-circle-check text-4xl text-green-600"></i></div>
                  <div className="text-2xl font-medium text-slate-400">Your PAN and ITR Plan has been submitted</div>
                  <div className="font-roboto text-xl">You will be caught up soon</div>
                  <div className="mt-4 text-3xl font-roboto font-bold text-slate-400">Thanks For Submitting, You will get response shortly</div>
                </div>
              </div>
            </>
  )
}

export default CompleteSelectionCard