import React, { useState, useContext, useEffect } from 'react';
import { unauthorizedaxios } from '../../axiosinit';
import jwtDecode from "jwt-decode";
import { pushNotification } from '../../socket';
import BrowserTitleName from '../BrowserTitleName';
import { BaseProvide } from './Base';
import { AppProvide } from '../../App';
import signupImage from '../../assets/signup.png';

function OTP() {
  const [otp, setOtp] = useState('');
  const Appcontext = useContext(AppProvide);
  const Basecontext = useContext(BaseProvide);

  const login = () => {
    unauthorizedaxios.post('/auth/client/login', { phone: Basecontext.phone })
      .then((res) => {
        if (res) {
          localStorage.setItem("access_token", res.data['access_token'])
          localStorage.setItem("refresh_token", res.data['refresh_token'])
          Basecontext.setPhone('')
          Basecontext.setEmail('')
          const decodedJwt = jwtDecode(res.data['access_token']);
          Appcontext.setUserInfo({ name: decodedJwt.name, email: decodedJwt.email, phone: decodedJwt.phone });
          Basecontext.navigate("/")
          window.location.reload()
        }
      })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    setTimeout(checkNavigate, 1000)
    // return () => clearTimeout(myTimeout)
  }, [])

  const checkNavigate = () => {
    if (Basecontext && !Basecontext.phone) {
      Basecontext.navigate("/")
    }
  }

  const auth = () => {
    Appcontext.setIs_loading(false)
    Appcontext.setIs_authenticated(true)
    login()
  }

  const register = () => {
    if (otp && (Basecontext.phone || Basecontext.email)) {
      Appcontext.setIs_loading(true)
      unauthorizedaxios.post('/auth/client_verify', { email: Basecontext.email, otp: otp })
        .then((res) => {
          if (res.status === 200) {
            if (!Appcontext.directLogin) {
              unauthorizedaxios.post('/auth/client_register', {
                first_name: Basecontext.f_name,
                last_name: Basecontext.l_name,
                email: Basecontext.email,
                phone: Basecontext.phone
              })
                .then((res) => {
                  if (res.status === 201) {
                    let client = res.data['client'][0]
                    pushNotification(client)
                    auth();
                  }
                })
            } else {
              auth()
            }
          }
        })
        .catch((err) => {
          if (err) {
            Appcontext.setIs_loading(false)
            Appcontext.setErrormsg(err.response.data['info'])
          }
        })
    }
  }

  const verifyOTPAndRegister = (e) => {
    e.preventDefault()
    register()
  }

  const resendOTP = () => {
    setOtp('')
    unauthorizedaxios.post('/auth/client_verify', { email: Basecontext.email })
      .then((res) => {
        Appcontext.setSuccessmsg(res.data['info'])
      })
  }

  return (
    <>
      <BrowserTitleName title="OTP Verification" />

      <div className="my-20 sm:container sm:mx-auto sm:flex sm:justify-center">
        <div className="px-4 mx-4 sm:mx-10 lg:mx-16 flex sm:inline-flex flex-col-reverse lg:flex-row bg-white">
          <div className="px-4 md:px-6 py-12 font-roboto">
            <div className="mb-10 md:mb-28 uppercase text-center text-3xl text-[#1e3751]">
              <div className="[text-shadow:_0_2px_0_rgb(0_0_0_/_60%)]">WELCOME TO TAXSUVIDHA 😀</div>
            </div>
            <div className="mb-10 md:mb-20 flex justify-center">
              <img className="w-[350px]" src={signupImage} alt={"signup"} />
            </div>
            <div className="m-auto mt-4 w-[70%] text-center">
              <span className="text-lg">
                Just upload your documents as directed by our CAs.
                TaxSuvidha will handle rest of the part.
              </span>
            </div>
          </div>
          <div className="px-6 sm:px-8 py-16 font-roboto bg-[#213c54]">
            {/* Login's div */}
            <div className="flex flex-col">
              <span className="pt-2 mt-2 font-semibold uppercase text-2xl text-[#00aeca]">Verify OTP</span>
              <div className="mb-10 w-[85%] text-white">
                Validate your account by entering the OTP received on <b>{Basecontext.email}</b>
              </div>
              <form onSubmit={verifyOTPAndRegister} className="flex flex-col space-y-6 lg:flex-row lg:space-x-4 lg:space-y-0 font-medium text-lg text-[#a2f0fd] bg-red-600">
                {/*<input className="lg:w-28 px-3 py-3.5 rounded placeholder:text-red-400 outline outline-1 outline-gray-400 bg-[#213c54] hover:outline-2 focus:outline-2 hover:outline-[#01a4bf] focus:outline-[#01a4bf]" type="text" value={"+91"} />*/}
                <input value={otp} onChange={(e) => setOtp(e.target.value)} className="lg:w-full px-3 py-3.5 rounded placeholder:text-red-400 outline outline-1 outline-gray-400 bg-[#213c54] hover:outline-2 focus:outline-2 hover:outline-[#01a4bf] focus:outline-[#01a4bf]" type="number" placeholder="OTP *" required />
              </form>
              <button onClick={verifyOTPAndRegister} className="mt-12 mb-3 py-2 rounded-lg space-x-4 text-white hover:bg-[#0090a7] bg-[#00aeca]">
                <span className="uppercase">Verify</span><span><i className="fa-solid fa-angle-right"></i></span>
              </button>
              <span className="text-white">Didn't receive an OTP? <em className="cursor-pointer text-[#7dedff]" onClick={resendOTP}>Resend OTP</em></span>
              <div className="text-white"></div>
              {/* <button onClick={resendOTP} className="py-2 rounded-lg space-x-4 text-white hover:bg-[#0090a7] bg-[#00aeca]">
                <span>Resend OTP</span><span><i class="fa-solid fa-angle-right"></i></span>
              </button> */}
            </div>

            {/* OTP's div */}
            {/* <div className="flex flex-col">
              <span className="pt-2 mt-2 font-semibold uppercase text-2xl text-[#00aeca]">
                Activate Account
              </span>
              <div className="mb-10 w-[85%] text-white">
                Validate your account by entering the OTP received on mobile-number
              </div>
              <form className="flex flex-col space-y-6 lg:flex-row lg:space-x-4 lg:space-y-0 font-medium text-lg text-[#a2f0fd]">
                <input className="w-full px-3 py-3.5 rounded placeholder:text-red-400 outline outline-1 outline-gray-400 bg-[#213c54] hover:outline-2 focus:outline-2 hover:outline-[#01a4bf] focus:outline-[#01a4bf]" type="text" placeholder="OTP *" />
              </form>
              <button className="mt-10 mb-4 py-2 rounded-lg space-x-4 text-white hover:bg-[#0090a7] bg-[#00aeca]">
                <span>SUBMIT</span><span><i class="fa-solid fa-angle-right"></i></span>
              </button>
              <div className="flex flex-col">
                <span className="text-white">Didn't receive an OTP?</span>
                <span className="text-[#74c0ce]">Resend OTP on mobile-number</span>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default OTP;
