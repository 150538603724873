import React, { useContext, useState, useEffect } from 'react';
import { unauthorizedaxios } from '../../axiosinit';
import BrowserTitleName from '../BrowserTitleName'
import { BaseProvide } from './Base';
import { AppProvide } from '../../App';
import { phoneValidate } from '../../phoneValidation';
import { emailValidate } from '../../emailValidation';
import signupImage from '../../assets/signup.png';

function Signup() {
  const Appcontext = useContext(AppProvide);
  const Basecontext = useContext(BaseProvide);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  const signup = (e) => {
    e.preventDefault();

    if (Basecontext.email && Basecontext.f_name && Basecontext.l_name && Basecontext.email) {
      Appcontext.setIs_loading(true)
      unauthorizedaxios.post('/auth/client_verify', { email: Basecontext.email })
        .then((res) => {
          if (res.status === 200) {
            Appcontext.setIs_loading(false)
            Basecontext.navigate("/otp-verify")
          }
        })
        .catch((err) => {
          if (err) {
            Appcontext.setIs_loading(false)
          }
        })
    }
  }

  useEffect(() => {
    window.scrollTo(0, 120);

    if (Basecontext.email !== '') {
      setIsEmailValid(emailValidate(Basecontext.email));
    }
  }, [Basecontext.email]);

  useEffect(() => {
    if (Basecontext.phone !== '') {
      setIsPhoneValid(phoneValidate(Basecontext.phone));
    }
  }, [Basecontext.phone]);

  return (
    <>
      <BrowserTitleName title="Signup" />

      <div className="my-20 sm:container sm:mx-auto sm:flex sm:justify-center">
        <div className="px-4 mx-4 sm:mx-10 lg:mx-16 flex sm:inline-flex flex-col-reverse lg:flex-row bg-white">
          <div className="px-4 md:px-6 py-12 font-roboto">
            <div className="mb-10 md:mb-20 uppercase text-center text-3xl text-[#1e3751]">
              <div className="[text-shadow:_0_2px_0_rgb(0_0_0_/_60%)]">WELCOME TO TAXSUVIDHA 😀</div>
            </div>
            <div className="mb-10 md:mb-20 flex justify-center">
              <img className="w-[350px]" src={signupImage} alt={"signup"} />
            </div>
            <div className="m-auto mt-4 w-[70%] text-center">
              <span className="text-lg">
                Just upload your documents as directed by our CAs.
                TaxSuvidha will handle rest of the part.
              </span>
            </div>
          </div>
          <div className="px-6 sm:px-8 py-12 font-roboto bg-[#213c54]">
            <div className="flex flex-col">
              <span className="pt-2 pb-6 my-2 font-semibold text-2xl text-[#00aeca]">
                Enter Your Details To Sign-up With Us
              </span>
              <form onSubmit={signup} className="mb-4 flex flex-col space-y-6 font-medium lg:text-lg text-[#a2f0fd]">
                <input value={Basecontext.f_name} onChange={(e) => Basecontext.setF_name(e.target.value)} className="px-3 py-3.5 rounded placeholder:text-red-400 outline outline-1 outline-gray-400 bg-[#213c54] hover:outline-2 focus:outline-2 hover:outline-[#01a4bf] focus:outline-[#01a4bf]" type="text" placeholder="First Name *" />
                <input value={Basecontext.l_name} onChange={(e) => Basecontext.setL_name(e.target.value)} className="px-3 py-3.5 rounded placeholder:text-red-400 outline outline-1 outline-gray-400 bg-[#213c54] hover:outline-2 focus:outline-2 hover:outline-[#01a4bf] focus:outline-[#01a4bf]" type="text" placeholder="Last Name *" />
                <div className="w-full">
                  <input value={Basecontext.email} onChange={(e) => {
                    Basecontext.setEmail(e.target.value)
                  }} className="w-full px-3 py-3.5 rounded placeholder:text-red-400 outline outline-1 outline-gray-400 bg-[#213c54] hover:outline-2 focus:outline-2 hover:outline-[#01a4bf] focus:outline-[#01a4bf]" type="text" placeholder="Email Address *" />
                  {
                    !isEmailValid &&
                    <span className="text-sm text-red-500">This is not a valid email</span>
                  }
                </div>
                <div className="w-full">
                  <input value={Basecontext.phone} onChange={(e) => {
                    Basecontext.setPhone(e.target.value)
                  }} className="w-full px-3 py-3.5 rounded placeholder:text-red-400 outline outline-1 outline-gray-400 bg-[#213c54] hover:outline-2 focus:outline-2 hover:outline-[#01a4bf] focus:outline-[#01a4bf]" type="text" placeholder="Phone Number *" />
                  {
                    !isPhoneValid &&
                    <span className="text-sm text-red-500">Phone Number must have 10 digits only</span>
                  }
                </div>
                <div className="mt-2 flex space-x-2">
                  <input className="rounded-md w-4" type="checkbox" name id defaultChecked="true" />
                  <div className="text-sm md:whitespace-nowrap">
                    <span className="text-white">I agree to the </span>
                    <em className="text-[#74c0ce]">Terms and Conditions.</em>
                  </div>
                </div>
                <button style={{ cursor: ((Basecontext.f_name === '' || Basecontext.l_name === '') || (Basecontext.email === '' || !isEmailValid) || (Basecontext.phone === '' || !isPhoneValid)) ? "not-allowed" : "pointer" }} className="mt-12 mb-8 py-2 rounded-lg space-x-4 text-white hover:bg-[#0090a7] bg-[#00aeca]" onClick={signup} disabled={(!isEmailValid) || (!isPhoneValid) || (Basecontext.phone === '')}>
                  <span>SIGN UP</span><span><i className="fa-solid fa-angle-right"></i></span>
                </button>
              </form>

              {/*<div className="text-sm md:whitespace-nowrap text-white">
                <em>Already have an account? <span className="text-[#74c0ce]">Sign in</span></em>
              </div>*/}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signup;
