import React from 'react';
import FeedbackCard from './FeedbackCard';
import client1 from '../../assets/review/client1.png';
import client2 from '../../assets/review/client2.png';
import client3 from '../../assets/review/client3.png';
import client4 from '../../assets/review/client4.png';
import client5 from '../../assets/review/client5.png';
import client6 from '../../assets/review/client6.png';

function Feedback() {
  return (
    <div className="mt-16 py-12 px-4 bg-[#daf3e1]">
      <div className="sm:px-10 sm:container sm:mx-auto">
        <div className="px-4 mb-10 capitalize font-poppins font-bold text-center text-5xl text-[#263238]">what our client say</div>
        <div className="flex flex-wrap justify-center gap-10">
          <FeedbackCard
            client={client4}
            clientName={"Esha Kumar"}
            feedback={"It was an amazing and fulfilling experience working with Kapil Mishra. He helped me end to end, with the ITR process and patiently attended to all my queries. Overall experience with TaxSuvidhaa is very good."}
            rating={5}
          />
          <FeedbackCard
            client={client2}
            clientName={"Raunak Khanna"}
            feedback={"TaxSuvidhaa is an amazing team. My experience was smooth as they assisted in tax filing end to end. They also answered all my queries even thus making me more aware tax payer."}
            rating={5}
          />
          <FeedbackCard
            client={client3}
            clientName={"Sachin Khurana"}
            feedback={"TaxSuvidhaa is really a great service. Kapil is dedicated and really helpful and will get you the best tax returns. Best ROI for your buck. Filling return process made very easy and quick. Well qualified professionals quickly resolving all your queries. Definitely reliable and I strongly recommend TaxSuvidhaa."}
            rating={5}
          />
          <FeedbackCard
            client={client5}
            clientName={"Clement Ranjith"}
            feedback={"Very Professional and Expert @Kapil Mishra. He is extremely helpful and answered all my queries in detail and with utmost patience and professionalism and I recommend TaxSuvidhaa for this reason."}
            rating={5}
          />
          <FeedbackCard
            client={client1}
            clientName={"Parveez S M"}
            feedback={"TaxSuvidhaa is one of the best Tax Consultant!!! I had a very good experience and had a very good support during my Tax Filling time. In fact after Tax filling they also support us on panning for the next year Tax saving calculation which is one of the key parameter for next Financial year planning. I strongly recommend the service to everyone. Thanks."}
            rating={5}
          />
          <FeedbackCard
            client={client6}
            clientName={"Ashwin Moh"}
            feedback={"Highly Professional Consultants who offer advice regarding tax saving and efficient return filing. Happy with tax filing services and will refer my friend."}
            rating={5}
          />
        </div>
      </div>
    </div>
  );
}

export default Feedback;
