import React, { useEffect } from 'react';
import Forms from './Forms';
import ContactWays from './ContactWays';
import { useNavigate } from 'react-router-dom';
import BrowserTitleName from "../BrowserTitleName";
import './contact.css';

function Contact() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BrowserTitleName title="Contact Us" />

      <div className="bg-img-2 relative font-[Roboto] h-[170px] xs:h-[180px] ms:h-[240px] md:h-[320px] xl:h-[400px] 2xl:h-[480px]">
        <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-white">
          <div className="flex flex-col justify-center items-center space-y-2">
            <div className="font-semibold text-center text-3xl ms:text-5xl sm:text-6xl 2xl:text-8xl text-[#06eb98]">Contact Us</div>
            <div className="flex flex-wrap justify-center items-center rounded bg-white">
              <button onMouseOver={() => {
                let globalButtons = window.document.getElementsByClassName("btn-gb");
                globalButtons[1].classList.add("text-[#2275ff]");
                globalButtons[1].classList.remove("bg-[#2275ff]");
              }} onMouseLeave={() => {
                let globalButtons = window.document.getElementsByClassName("btn-gb");
                globalButtons[1].classList.remove("text-[#2275ff]");
                globalButtons[1].classList.add("bg-[#2275ff]");
              }} className="btn-gb px-5 py-2 font-semibold rounded-l duration-200 text-[#2275ff] hover:text-white hover:bg-[#2275ff]" onClick={() => navigate('/')}>Home</button>
              <button className="btn-gb px-5 py-2 font-semibold w-full ms:w-auto rounded-r duration-200 bg-[#2275ff]">Contact Us</button>
            </div>
          </div>
        </div>
      </div>
      <Forms />
      <ContactWays />
    </>
  );
}

export default Contact;
