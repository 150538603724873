import React, { useState, useEffect } from 'react';
import calc from "../../../assets/calc/calc.png";
import house from "../../../assets/calc/house.png";
import { formattedPrice } from "../../../formattedPrice";
import {
  Ripple,
  Input,
  initTE,
} from "tw-elements";

function HRA() {
  const currentYear = new Date().getFullYear();
  const [city, setCity] = useState('');
  const [HRA, setHRA] = useState('');
  const [rent, setRent] = useState('');
  const [salary, setSalary] = useState('');
  const [exemptHRA, setExemptHRA] = useState('');
  const [isCalculated, setIsCalculated] = useState(false);

  const againCalculate = e => {
    e.preventDefault();
    setIsCalculated(false);
  }

  const secondFormula = () => {
    return rent - (salary * 0.1);
  }

  const thirdFormula = () => {
    return (city === 'y') ? salary * 0.5 : salary * 0.4;
  }

  const calculateExemptHRA = e => {
    e.preventDefault();

    if (HRA !== '' && rent !== '' && salary !== '' && city !== '') {
      console.log(city, HRA, rent, salary);
      setExemptHRA(Math.min(HRA, secondFormula(), thirdFormula()));
      setIsCalculated(true);
    }
  }

  useEffect(() => {
    initTE({ Ripple, Input });
  }, [isCalculated]);

  return (
    <div className="px-10 pt-2 pb-4">
      {
        isCalculated ?
          <div className="flex flex-col mb-4 font-roboto">
            <div className="mb-2 font-bold text-xl text-blue-600">Taxable HRA & Exempt HRA : ₹{formattedPrice(HRA)}</div>
            <table className="min-w-full">
              <tbody>
                <tr>
                  <td className="break-all xs:break-keep text-start border border-slate-400 p-3 w-[85%]">Taxable HRA</td>
                  <td className="text-end border border-slate-400 p-3">₹{formattedPrice(HRA - exemptHRA)}</td>
                </tr>
                <tr>
                  <td className="break-all xs:break-keep text-start border border-slate-400 p-3 w-[85%]">Exempt HRA</td>
                  <td className="text-end border border-slate-400 p-3">₹{formattedPrice(exemptHRA)}</td>
                </tr>
                <tr>
                  <td className="break-all xs:break-keep text-start border border-slate-400 p-3 w-[85%]">Total HRA</td>
                  <td className="text-end border border-slate-400 p-3">₹{formattedPrice(HRA)}</td>
                </tr>
              </tbody>
            </table>
            <div className="mt-10 mb-2 font-bold text-xl text-blue-600">List of the following is exempt [Section 10(13A)]</div>
            <table className="min-w-full">
              <tbody>
                <tr className="bg-gray-50">
                  <td className="text-center border border-slate-400 p-3 w-[4%]">1</td>
                  <td className="break-all ms:break-keep text-start border border-slate-400 p-3 w-[86%]">HRA actually received</td>
                  <td className="text-end border border-slate-400 p-3">₹{formattedPrice(HRA)}</td>
                </tr>
                <tr className="bg-gray-200">
                  <td className="text-center border border-slate-400 p-3 w-[4%]">2</td>
                  <td className="break-all ms:break-keep text-start border border-slate-400 p-3 w-[86%]">Rent paid (-) 10% of salary</td>
                  <td className="text-end border border-slate-400 p-3">₹{formattedPrice(secondFormula())}</td>
                </tr>
                <tr className="bg-gray-50">
                  <td className="text-center border border-slate-400 p-3 w-[4%]">3</td>
                  <td className="break-all ms:break-keep text-start border border-slate-400 p-3 w-[86%]">50% of salary, if located in metro city <b>OR</b> 40% of salary, if located in any non metro city</td>
                  <td className="text-end border border-slate-400 p-3">₹{formattedPrice(thirdFormula())}</td>
                </tr>
                <tr className="bg-gray-200">
                  <td className="text-center border border-slate-400 p-3 w-[4%]">4</td>
                  <td className="break-all ms:break-keep text-start border border-slate-400 p-3 w-[86%]">Exempt HRA</td>
                  <td className="text-end border border-slate-400 p-3">₹{formattedPrice(exemptHRA)}</td>
                </tr>
              </tbody>
            </table>
            <button className="w-full ms:w-auto ms:self-end uppercase mt-4 font-roboto px-6 py-2 rounded-sm shadow-sm shadow-slate-600 focus:shadow-none font-semibold text-lg text-white bg-[#ff9f00] focus:bg-[#e28f09]" onClick={againCalculate}>Calculate Again</button>
          </div> :
          <>
            <div className="flex">
              <div className="relative w-28 h-28 rounded-br-full bg-[#494547]">
                <img className="w-16" src={calc} alt="" />
                <img className="absolute bottom-[26px] right-[25px] w-16" src={house} alt="" />
              </div>
            </div>
            <div className="py-4 mt-4">
              <form className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4">
                <div className="font-roboto">
                  <label htmlFor="countries" className="block mb-2 text-sm font-medium text-gray-900">Select Financial Year</label>
                  <select id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg outline-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                    <option value=''> -- Choose Financial Year -- </option>
                    <option value={`${currentYear - 1}-${currentYear}`}>{currentYear - 1} - {currentYear}</option>
                    <option value={`${currentYear - 2}-${currentYear - 1}`}>{currentYear - 2} - {currentYear - 1}</option>
                    <option value={`${currentYear - 3}-${currentYear - 2}`}>{currentYear - 3} - {currentYear - 2}</option>
                    <option value={`${currentYear - 4}-${currentYear - 3}`}>{currentYear - 4} - {currentYear - 3}</option>
                  </select>
                </div>
                <div className="font-roboto">
                  <label htmlFor="countries" className="block mb-2 text-sm font-medium text-gray-900">Select City Type <b className="text-red-600">*</b></label>
                  <select value={city} id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg outline-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" onChange={e => setCity(e.target.value)}>
                    <option value=''> -- Choose City Type -- </option>
                    <option value="y">Metro City</option>
                    <option value="n">Non Metro City</option>
                  </select>
                </div>
                <div className="relative font-roboto" data-te-input-wrapper-init>
                  <input
                    type="number"
                    className="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[2.6] lg:leading-[3.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0 font-medium text-blue-800"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                    value={HRA}
                    onChange={e => setHRA(e.target.value)}
                  />
                  <label htmlFor="exampleInputEmail1" className="font-roboto pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none">
                    ₹ HRA Received P.A.<b className="text-red-600">*</b>
                  </label>
                </div>
                <div className="relative font-roboto" data-te-input-wrapper-init>
                  <input
                    type="number"
                    className="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[2.6] lg:leading-[3.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0 font-medium text-blue-800"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                    value={rent}
                    onChange={e => setRent(e.target.value)}
                  />
                  <label htmlFor="exampleInputEmail1" className="font-roboto pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none">
                    ₹ Rent Paid P.A.<b className="text-red-600">*</b>
                  </label>
                </div>
                <div className="relative font-roboto" data-te-input-wrapper-init>
                  <input
                    type="number"
                    className="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[2.6] lg:leading-[3.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0 font-medium text-blue-800"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                    value={salary}
                    onChange={e => setSalary(e.target.value)}
                  />
                  <label htmlFor="exampleInputEmail1" className="font-roboto pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none">
                    ₹ Basic Salary P.A.<b className="text-red-600">*</b>
                  </label>
                </div>
                {
                  (HRA !== '' && rent !== '' && salary !== '' && city !== '') ?
                    <button className="font-roboto py-2 sm:ml-auto sm:w-[50%] rounded-sm shadow-sm shadow-slate-600 focus:shadow-none font-semibold text-lg text-white bg-[#fb641b] focus:bg-[#e45610]" onClick={calculateExemptHRA}>Calculate</button> :
                    <button className="font-roboto py-2 sm:ml-auto sm:w-[50%] rounded-sm font-semibold text-lg text-white bg-slate-400" disabled={true}>Calculate</button>
                }
              </form>
            </div>
          </>
      }
    </div>
  );
}

export default HRA;
