import { useState, createContext } from "react";
import Home from "./Home";
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import Signup from "./Signup";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import OTP from "./OTP";
import Contact from "../contact/Contact";
import PricingHome from "../pricing/PricingHome";
import Notices from "../pricing/Notices";
import Pricing from "../pricing/Pricing";
import TaxPricing from "../pricing/TaxPricing";
import Calculators from "../calculators/Calculators";
import Page404 from "../404/Page404";

export const BaseProvide = createContext();

const Base = () => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [f_name, setF_name] = useState('');
  const [l_name, setL_name] = useState('');
  const [client_id, setClient_id] = useState('');

  const data = {
    navigate,
    phone, setPhone,
    email, setEmail,
    setF_name, f_name,
    setL_name, l_name,
    setClient_id, client_id
  };

  return (
    <>
      <BaseProvide.Provider value={data}>
        <Navbar />
        <Routes>
          <Route exact path={"/"} element={<Home />} />
          <Route exact path={"/otp-verify"} element={<OTP />} />
          <Route exact path={"/signup"} element={<Signup />} />
          <Route exact path={"/pricing/"} element={<PricingHome />}>
            <Route path={""} element={<Navigate to={"itr"} />} />
            <Route path={"itr"} element={<Pricing />} />
            <Route path={"tax"} element={<TaxPricing />} />
            <Route path={"notices"} element={<Notices />} />
          </Route>
          <Route exact path={"/contact"} element={<Contact />} />
          <Route exact path={"/calculators"} element={<Calculators />} />
          <Route exact path={"/*"} element={<Page404 />} />
        </Routes>
        <Footer />
      </BaseProvide.Provider>
    </>
  )
}

export default Base;
