import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';

function SuccessToast({ message, setMessage }) {
  useEffect(() => {
    if (message !== '') {
      toast.success(message, {
        position: "top-center",
        autoClose: 700
      });
      setMessage('');
    }
  }, [message]);

  return (
    <>
      <div>
        <ToastContainer />
      </div>
    </>
  );
}

export default SuccessToast;
