import React, { useContext, useState } from "react";
import navBrand from "../../assets/TAX-SUVIDHAA-PNG.png";
import { NavLink, useNavigate } from "react-router-dom";
import { AppProvide } from "../../App";
import "./Navbar.css";

function Navbar() {
  const navigate = useNavigate();
  const appContext = useContext(AppProvide);
  const [isProfileOpen, setIsProfileOpen] = useState(false);

  const hamburgerAnimation = () => {
    let navMenu = document.getElementsByClassName("nav-menu")[0];
    let hamburger = document.getElementsByClassName("hamburger")[0].childNodes[0];
    let bar1 = hamburger.childNodes[0];
    let bar2 = hamburger.childNodes[1];
    let bar3 = hamburger.childNodes[2];

    if (bar2.style.opacity === "0") {
      bar1.style.transform = "rotate(0deg)";
      bar2.style.opacity = 1;
      bar3.style.transform = "rotate(0deg)";
      navMenu.classList.add("hidden");
    } else {
      bar1.style.transform = "rotate(45deg)";
      bar2.style.opacity = 0;
      bar3.style.transform = "rotate(-45deg)";
      navMenu.classList.remove("hidden");
    }
  }

  return (
    <nav className="z-10 sticky top-0 p-5 bg-[#1e2c39]">
      <div className="container mx-auto flex flex-wrap items-center">
        <div className="" onClick={() => navigate('/')}>
          <img className="w-28 cursor-pointer" src={navBrand} alt={"nav-logo"} />
        </div>
        <div className="hamburger md:hidden inline-flex ml-auto" onClick={hamburgerAnimation}>
          <div className="space-y-2">
            <div className="bar-1"></div>
            <div className="bar-2"></div>
            <div className="bar-3"></div>
            {/* <div className="w-[34px] h-[4px] rounded-full bg-white"></div>
              <div className="w-[34px] h-[4px] rounded-full bg-white"></div>
              <div className="w-[34px] h-[4px] rounded-full bg-white"></div> */}
          </div>
        </div>
        <div className="nav-menu hidden mt-4 md:mt-0 md:ml-4 w-full md:w-auto md:inline-flex md:flex-grow">
          <ul className="ml-auto md:inline-flex md:flex-row text-xl md:text-lg font-medium text-white">
            <li className="cursor-pointer rounded" onClick={() => navigate("/")}><NavLink className="block px-2.5 py-4 md:px-6 md:py-2" to="/">Home</NavLink></li>
            <li className="cursor-pointer rounded" onClick={() => navigate("/pricing")}><NavLink className="block px-2.5 py-4 md:px-6 md:py-2" to="/pricing">Pricing</NavLink></li>
            <li className="cursor-pointer rounded" onClick={() => navigate("/contact")}><NavLink className="block px-2.5 py-4 md:px-6 md:py-2" to="/contact">Contact</NavLink></li>
            <li className="cursor-pointer rounded" onClick={() => navigate("/calculators")}><NavLink className="block px-2.5 py-4 md:px-6 md:py-2" to="/calculators">Calculators</NavLink></li>
            {
              appContext.is_authenticated &&
              <div className="relative">
                <div className="user-info select-none flex items-center space-x-2 cursor-pointer rounded mt-4 md:mt-0 md:ml-2 px-2.5 py-2 md:px-6 md:py-2 hover:bg-slate-600" onClick={() => {
                  setIsProfileOpen(!isProfileOpen);
                  document.getElementsByClassName("user-info")[0].classList.toggle("user-info-shade");
                }}>
                  <span><i className="fa-solid fa-circle-user text-2xl"></i></span>
                  <span className="font-roboto font-normal">{appContext.userInfo?.name}</span>
                </div>
                {
                  isProfileOpen &&
                  <div className="p-4 absolute top-[60px] left-[-0.9rem] xs:left-auto md:top-[55px] md:right-0 rounded shadow-md shadow-slate-400 text-black bg-white">
                    <div className="w-[14.5rem] font-poppins flex flex-col text-slate-900">
                      <div className="text-[1.0rem] break-all">{appContext.userInfo?.email}</div>
                      <div className="text-[1.0rem] break-all">{appContext.userInfo?.phone}</div>
                      <hr className="my-2 h-0.5 bg-slate-200" />
                      <button className="p-1 font-roboto flex items-center space-x-2 rounded-sm text-slate-500 hover:bg-slate-200" onClick={() => {
                        appContext.setIs_authenticated(false);
                        localStorage.clear();
                      }}>
                        <span><i className="fa-solid fa-right-from-bracket"></i></span>
                        <span>Sign Out</span>
                      </button>
                    </div>
                  </div>
                }
              </div>
            }
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
