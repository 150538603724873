import React, { createContext, useState, useMemo, useContext, useEffect } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import Footer from '../footer/Footer'
import Navbar from '../navbar/Navbar'
import Filing from './Filing'
import Contact from '../contact/Contact'
import Notices from '../pricing/Notices'
import Pricing from '../pricing/Pricing'
import TaxPricing from '../pricing/TaxPricing'
import PricingHome from '../pricing/PricingHome'
import Calculators from '../calculators/Calculators'
import { authorizedaxios } from '../../axiosinit'
import { AppProvide } from '../../App'
import jwtDecode from 'jwt-decode'
import { collectNewToken } from '../../collectToken'
import Page404 from '../404/Page404'

export const AuthContext = createContext();

const AuthorizedBase = () => {
  const Appcontext = useContext(AppProvide);
  const [isPlanSelected, setIsPlanSelected] = useState(false);
  const [loop, setLoop] = useState(0);

  useMemo(() => {
    if (localStorage.getItem('access_token')) {
      authorizedaxios(localStorage.getItem('access_token')).get(`/client/is_plan_selected/client_id=${jwtDecode(localStorage.getItem('access_token')).sub}`)
        .then(res => {
          console.log(res);
          const data = res.data['exist'];
          (data === "false") ? setIsPlanSelected(false) : setIsPlanSelected(true);
        })
        .catch(err => {
          console.log(err);
        })
    }
  }, []);

  // useEffect(() => {
  //   const id = setInterval(() => setLoop(Math.random()), 5000);
  //   return () => clearInterval(id)
  // }, []);

  useEffect(() => {
    if (localStorage.getItem('access_token') && (jwtDecode(localStorage.getItem('access_token')).exp <= Math.floor(Date.now() / 1000))) {
      try {
        let stat = collectNewToken(jwtDecode(localStorage.getItem('access_token')).sub)
        const decode = jwtDecode(localStorage.getItem('refresh_token'));
        Appcontext.setClient_id(decode.sub)
        Appcontext.setUserInfo({ name: decode.name, email: decode.email, phone: decode.phone });
      } catch (err) {
        console.log(err);
      }
    }
  }, [loop])

  let values = {
    isPlanSelected, setIsPlanSelected
  }

  return (
    <>
      <AuthContext.Provider value={values}>
        <Navbar />
        <Routes>
          <Route exact path={"/"} element={<Filing />} />
          <Route exact path={"/pricing/"} element={<PricingHome />}>
            <Route path={""} element={<Navigate to={"itr"} />} />
            <Route path={"itr"} element={<Pricing />} />
            <Route path={"tax"} element={<TaxPricing />} />
            <Route path={"notices"} element={<Notices />} />
          </Route>
          <Route exact path={"/contact"} element={<Contact />} />
          <Route exact path={"/calculators"} element={<Calculators />} />
          <Route exact path={"/*"} element={<Page404 />} />
        </Routes>
        <Footer />
      </AuthContext.Provider>
    </>
  )
}

export default AuthorizedBase;
