import React from 'react';
import Service from './Service';
import GST from "../../assets/services/GST.jpg";
import ROC from "../../assets/services/ROC.jpg";
import TDS from "../../assets/services/TDS.jpg";
import tax from "../../assets/services/tax.jpg";
import finance from "../../assets/services/finance.jpg";
import auditing from "../../assets/services/auditing.jpg";
import business from "../../assets/services/business.jpg";
import corporate from "../../assets/services/corporate.jpg";
import accounting from "../../assets/services/accounting.jpg";

function Services() {
  return (
    <div className="mb-4">
      <div className="my-10 flex justify-between items-center">
        <div className="w-full h-[0.05rem] bg-white"></div>
        <div className="flex space-x-2 bg-yellow-200">
          <div className="uppercase whitespace-nowrap mx-[0.180rem] px-2 text-2xl text-white bg-[#1e2c39]">Our Services</div>
        </div>
        <div className="w-full h-[0.05rem] bg-white"></div>
      </div>
      <div className="font-roboto 2xl:px-40 flex flex-col justify-center items-center md:grid md:grid-cols-2 lg:grid lg:grid-cols-3 md:gap-x-6 lg:gap-x-10 gap-y-12">
        <Service title={"Accounting"} image={accounting} />
        <Service title={"Business Process"} image={business} />
        <Service title={"ROC Filing"} image={ROC} />
        <Service title={"GST Filing"} image={GST} />
        <Service title={"TDS Working"} image={TDS} />
        <Service title={"Auditing & Attest"} image={auditing} />
        <Service title={"Income Tax"} image={tax} />
        <Service title={"Financial Services"} image={finance} />
        <Service title={"Corporate Law"} image={corporate} />
      </div>
    </div>
  );
}

export default Services;
