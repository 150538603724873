import React from 'react';
import ReactStars from 'react-stars';
import google from '../../assets/google.png';
import verified from '../../assets/verified.png';

function FeedbackCard({ client, clientName, feedback, rating }) {
  return (
    <div className="p-6 w-[300px] rounded-xl shadow-lg self-start bg-white">
      <div className="flex flex-col gap-6">
        <div className="flex items-center">
          <div className="">
            <img src={client} alt="client" />
          </div>
          <div className="font-poppins ml-2 font-bold text-slate-800">{clientName}</div>
          <div className="ml-1">
            <img className="w-4 rounded-full" src={verified} alt="verified" />
          </div>
        </div>
        <div className="font-roboto text-slate-600">
          <div className="">{feedback}</div>
          <div className="mt-6 flex items-center gap-2">
            <div>
              <img className="w-8" src={google} alt="google" />
            </div>
            <div className="">
              <div className="font-poppins font-semibold text-slate-800">Google Reviews</div>
              <div className="">
                <ReactStars
                  value={rating}
                  count={5}
                  edit={false}
                  size={24}
                  color2={'#ffbc00'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeedbackCard;
