import React, { useEffect, useState } from "react";
import PricingCard from "./PricingCard";
import BrowserTitleName from "../BrowserTitleName";
import PricingInfoModal from "./PricingInfoModal";

function Pricing() {
  const [moreInfoTitle, setMoreInfoTitle] = useState('');

  const salaryAndHPPlan = [
    "Single & Multiple Employers",
    "Single & Multiple House Property",
    "Income from Other Sources",
    "Agriculture Income"
  ];

  const businessAndProfessionalPlan = [
    "Single & Multiple Employers",
    "Single & Multiple House Property",
    "Business & Professional Income (Non Audit) - Without B/S P/L*",
    "Income from Other Sources",
    "Agriculture Income"
  ];

  const capitalGainPlan = [
    "Single & Multiple Employers",
    "Single & Multiple House Property",
    "Multiple Capital Gain Income",
    "Business & Professional Income(Non Audit)- Without B/S P/L*",
    "Income from Other Sources",
    "Agriculture Income"
  ];

  const futureAndOptionsPlan = [
    "F&O Income/Loss (Non Audit)",
    "Speculative Income",
    "Single & Multiple Employers",
    "Single & Multiple House Property",
    "Multiple Capital Gain Income",
    "Income from Other Sources",
    "Business & Professional Income(Non Audit)- Without B/S P/L*",
    "Agriculture Income"
  ];

  const cryptoCurrencyPlan = [
    "Crypto Income",
    "F&O Income/Loss (Non Audit)",
    "Speculative Income",
    "Single & Multiple Employers",
    "Single & Multiple House Property",
    "Multiple Capital Gain Income",
    "Business & Professional Income(Non Audit)- Without B/S P/L*",
    "Income from Other Sources",
    "Agriculture Income"
  ];

  const residentHavingForeignIncome = [
    "Foreign salary(Including Foreign Tax relief)",
    "DTAA Tax Relief",
    "Single & Multiple Employers",
    "Single & Multiple House Property",
    "Multiple Capital Gain Income",
    "RSU/ESOP",
    "Business & Professional Income(Non Audit)- Without B/S P/L*",
    "Crypto Income",
    "F&O Income/Loss (Non Audit)",
    "Speculative Income",
    "Income from Other Sources",
    "Agriculture Income"
  ];

  function showPricingInfoModal() {
    window.document.getElementById("popup-price-info-modal").classList.add("flex");
    window.document.getElementById("popup-price-info-modal").classList.remove("hidden");
  }

  function hidePricingInfoModal() {
    window.document.getElementById("popup-price-info-modal").classList.add("hidden");
    window.document.getElementById("popup-price-info-modal").classList.remove("flex");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BrowserTitleName title="ITR Pricing" />
      <PricingInfoModal title={moreInfoTitle} close={hidePricingInfoModal} />

      <div className="py-10 border-b bg-[#111827]">
        <div className="ms:container ms:mx-auto">
          <div className="mb-7 font-poppins font-semibold text-center text-3xl text-white">Plans depend on your Choice</div>
          <div className="px-4 sm:px-0 py-4 flex flex-wrap justify-center gap-8 gap-y-10">
            <div className="w-full sm:w-auto">
              <PricingCard
                title="Salary &amp; HP Plan"
                actualPrice={1199}
                discountPrice={699}
                suitedFor
                conditions={salaryAndHPPlan}
                readMoreComponent={<span className="sm:w-[300px] cursor-pointer text-blue-500" onClick={() => { setMoreInfoTitle("Salary & HP Plan"); showPricingInfoModal(); }}>Read More</span>}
              />
            </div>
            <div className="w-full sm:w-auto">
              <PricingCard
                title="Business &amp; Professional Plan"
                actualPrice={2499}
                discountPrice={1999}
                suitedFor
                conditions={businessAndProfessionalPlan}
                readMoreComponent={<span className="sm:w-[300px] cursor-pointer text-blue-500" onClick={() => { setMoreInfoTitle("Business & Professional Plan"); showPricingInfoModal(); }}>Read More</span>}
              />
            </div>
            <div className="w-full sm:w-auto">
              <PricingCard
                title="Capital Gain Plan"
                actualPrice={2999}
                discountPrice={2499}
                suitedFor
                conditions={capitalGainPlan}
                readMoreComponent={<span className="sm:w-[300px] cursor-pointer text-blue-500" onClick={() => { setMoreInfoTitle("Capital Gain Plan"); showPricingInfoModal(); }}>Read More</span>}
              />
            </div>
            <div className="w-full sm:w-auto">
              <PricingCard
                title="Future &amp; Options Plan"
                actualPrice={4999}
                discountPrice={3999}
                suitedFor
                conditions={futureAndOptionsPlan}
                readMoreComponent={<span className="sm:w-[300px] cursor-pointer text-blue-500" onClick={() => { setMoreInfoTitle("Future & Options Plan"); showPricingInfoModal(); }}>Read More</span>}
              />
            </div>
            <div className="w-full sm:w-auto">
              <PricingCard
                title="Crypto Currency Plan"
                actualPrice={7500}
                discountPrice={5999}
                suitedFor
                conditions={cryptoCurrencyPlan}
                readMoreComponent={<span className="sm:w-[300px] cursor-pointer text-blue-500" onClick={() => { setMoreInfoTitle("Crypto Currency Plan"); showPricingInfoModal(); }}>Read More</span>}
              />
            </div>
            <div className="w-full sm:w-auto">
              <PricingCard
                title="NRI / Resident Having Foreign Income"
                actualPrice={7500}
                discountPrice={5999}
                suitedFor
                conditions={residentHavingForeignIncome}
                readMoreComponent={<span className="sm:w-[300px] cursor-pointer text-blue-500" onClick={() => { setMoreInfoTitle("NRI / Resident Having Foreign Income"); showPricingInfoModal(); }}>Read More</span>}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Pricing;
