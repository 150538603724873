import React, { useState, useEffect, useContext } from "react";
import { panValidate } from "../../panvalidation";
import { AppProvide } from "../../App";
import { authorizedaxios } from "../../axiosinit";
import BrowserTitleName from "../BrowserTitleName";
import { AuthContext } from "./AuthorizedBase";
import CompleteSelectionCard from "./CompleteSelectionCard";

function Filing() {
  const Appcontext = useContext(AppProvide);
  const Authcontext = useContext(AuthContext);

  const [pan, setPan] = useState('');
  const [panISok, setPanIsok] = useState(false);
  const [toggle, setToggle] = useState('');
  const [plan, setPlan] = useState('');
  const [completeState, setCompleteState] = useState(0);
  const [successtext, setSuccessText] = useState("");

  useEffect(() => {
    if(pan!==''){if (panValidate(pan)) {
      setPanIsok(true);
    } else {
      setPanIsok(false);
    }}
  }, [pan]);

  const Toggle = (val) => {
    setToggle(val);

    if (val === toggle) {
      setToggle('');
      setPlan('');
    } else {
      setToggle(val);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    if (panISok === false && toggle === '') {
      setCompleteState(0);
    } else if ((panISok === true && toggle === '') || (panISok === false && toggle !== '')) {
      setCompleteState(50);
    } else {
      setCompleteState(100);
    }
  }, [panISok, toggle]);

  const submitDetails = () => {
    if (!pan && plan) {
      alert("Enter your pan number!")
    }
    else if (!plan && pan) {
      alert("Select your plan!")
    }
    else if (!pan && !plan) {
      alert("Enter your pan number and select plan!")
    }
    else if (panISok && plan && pan) {
      const data = {
        "pan": pan,
        "itr_plan": plan
      }
      //console.log(data)
      Appcontext.setIs_loading(true)
      authorizedaxios(localStorage.getItem('access_token')).post(`/client/plan_selection/client_id=${Appcontext.client_id}`, data)
        .then((res) => {
          console.log(res)
          if (res.status === 200) {
            // Appcontext.setSuccessmsg(res.data['info'])
            setPan('')
            setCompleteState(0)
            setToggle('')
            setPanIsok(false)
            setPlan('')
            Appcontext.setIs_loading(false)
            const data = res.data['exist'];
            (data === "false") ? Authcontext.setIsPlanSelected(false) : Authcontext.setIsPlanSelected(true);

            // setSuccessText("Thanks For Submitting. You will get response shortly.")
            window.scrollTo(0, 0);
          }
        })
        .catch((err) => {  
            Appcontext.setErrormsg(err.response.data['info'])
        })
    }
  }

  return (
    <>
      <BrowserTitleName title="ITR Planning Form" />

      <div className="m-4">
        {
          Authcontext?.isPlanSelected ?
            <CompleteSelectionCard /> :
            <>
              <div className="sm:container sm:mx-auto p-4 rounded-md shadow-md shadow-slate-400 bg-white">
                <div className="font-roboto flex flex-col bg-orange-200">
                  <div className="px-6 py-6 sm:p-6 flex flex-col-reverse gap-2 ms:flex-row ms:justify-between ms:items-center ms:space-x-2 ms:space-y-0 bg-[#d4f3f8]">
                    <span className="text-sm sm:text-lg font-medium text-slate-800">Your Form is {completeState}% complete</span>
                    {/* <span className="text-sm sm:text-lg px-4 py-2 rounded bg-[#7adcec]">103 days away</span> */}
                  </div>
                  <div className="h-1 bg-orange-400" style={{ width: `${completeState}%` }}></div>
                  <div className="px-4 py-6 bg-white">

                    <form onSubmit={(e) => e.preventDefault()} className="space-y-4">
                      <span className="text-green-600 text-lg font-bold">{successtext && successtext}</span>
                      <div className="flex items-center space-x-2">
                        <span className="px-3 py-1 text-center rounded-full text-white bg-orange-600">1</span>
                        <span className="text-sm font-medium">Add Information</span>
                      </div>
                      <div className="px-2 sm:px-10">
                        <label className="font-medium text-slate-800">PAN Number <span className="text-sm font-semibold text-red-600">*</span></label>
                        <input value={pan} className={panISok ? "px-3 py-3 w-full font-medium rounded border outline-none shadow shadow-slate-500 border-[#29c231] text-[#29c231]" : "px-3 py-3 w-full font-medium rounded border outline-none shadow shadow-slate-500 border-red-600 text-red-600"} type="text" name="" id="" placeholder="Example PAN: AAAPL1234C" onChange={(e) => setPan((e.target.value).toUpperCase())} />
                        {
                          panISok ?
                            <span className="text-sm font-medium text-[#2ab131]"><i className="fa-solid fa-check"></i> This is a valid PAN</span> :
                            <span className="text-sm text-red-600"><i className="fa-solid fa-circle-exclamation"></i> PAN is empty or invalid</span>
                        }
                      </div>
                      <div className="flex items-center space-x-2">
                        <span className="px-3 py-1 text-center rounded-full text-white bg-orange-600">2</span>
                        <span className="text-sm font-medium">Select a Plan</span>
                      </div>
                      <div className="px-2 sm:px-10 flex flex-col space-y-3" >
                        <span className={toggle === '1st' ? "filing-option text-[#00aeca] bg-[#d4f3f8]" : "filing-option text-white bg-[#00aeca] hover:text-[#00aeca] hover:bg-[#d4f3f8]"} onClick={() => {
                          setPlan('1')
                          Toggle('1st')
                        }}>
                          {toggle === '1st' && <span><i className="fa-solid fa-circle-check text-xl"></i></span>}
                          <span>Salary &amp; HP Plan ₹699</span>
                        </span>
                        <span className={toggle === '2nd' ? "filing-option text-[#00aeca] bg-[#d4f3f8]" : "filing-option text-white bg-[#00aeca] hover:text-[#00aeca] hover:bg-[#d4f3f8]"} onClick={() => {
                          setPlan('2')
                          Toggle('2nd')
                        }}>
                          {toggle === '2nd' && <span><i className="fa-solid fa-circle-check text-xl"></i></span>}
                          <span>Business &amp; Professional Plan ₹1999</span>
                        </span>
                        <span className={toggle === '3rd' ? "filing-option text-[#00aeca] bg-[#d4f3f8]" : "filing-option text-white bg-[#00aeca] hover:text-[#00aeca] hover:bg-[#d4f3f8]"} onClick={() => {
                          setPlan('3')
                          Toggle('3rd')
                        }}>
                          {toggle === '3rd' && <span><i className="fa-solid fa-circle-check text-xl"></i></span>}
                          <span>Capital Gain Plan ₹2499</span>
                        </span>
                        <span className={toggle === '4th' ? "filing-option text-[#00aeca] bg-[#d4f3f8]" : "filing-option text-white bg-[#00aeca] hover:text-[#00aeca] hover:bg-[#d4f3f8]"} onClick={() => {
                          setPlan('4')
                          Toggle('4th')
                        }}>
                          {toggle === '4th' && <span><i className="fa-solid fa-circle-check text-xl"></i></span>}
                          <span>Future &amp; Options Plan ₹3999</span>
                        </span>
                        <span className={toggle === '5th' ? "filing-option text-[#00aeca] bg-[#d4f3f8]" : "filing-option text-white bg-[#00aeca] hover:text-[#00aeca] hover:bg-[#d4f3f8]"} onClick={() => {
                          setPlan('5')
                          Toggle('5th')
                        }}>
                          {toggle === '5th' && <span><i className="fa-solid fa-circle-check text-xl"></i></span>}
                          <span>Crypto Currency Plan ₹5999</span>
                        </span>

                        <span className={toggle === '6th' ? "filing-option text-[#00aeca] bg-[#d4f3f8]" : "filing-option text-white bg-[#00aeca] hover:text-[#00aeca] hover:bg-[#d4f3f8]"} onClick={() => {
                          setPlan('6')
                          Toggle('6th')
                        }}>
                          {toggle === '6th' && <span><i className="fa-solid fa-circle-check text-xl"></i></span>}
                          <span>NRI / Resident Having Foreign Income ₹5999</span>
                        </span>
                      </div>
                      <div className="px-2 sm:px-10">
                        <button onClick={submitDetails} type="button" className="px-6 py-2 rounded shadow shadow-slate-600 focus:shadow-none text-white bg-orange-500 hover:bg-orange-600">Submit</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </>
        }
      </div>
    </>
  );
}

export default Filing;
