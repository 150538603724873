import React from 'react';
import ServiceModal from "./ServiceModal";

function Service({ title, image }) {
  function showServiceModal() {
    window.document.getElementById("popup-service-modal").classList.add("flex");
    window.document.getElementById("popup-service-modal").classList.remove("hidden");
  }

  function hideServiceModal() {
    window.document.getElementById("popup-service-modal").classList.add("hidden");
    window.document.getElementById("popup-service-modal").classList.remove("flex");
  }

  return (
    <>
      <ServiceModal close={hideServiceModal} title={"Range Of Services"} />

      <div className="box rounded w-[90%] xs:w-[80%] ms:w-[72.5%] sm:w-[65%] md:w-auto">
        <div className="h-40 ms:h-48 rounded-t overflow-hidden">
          <img className="w-full h-52 ms:h-64 duration-300 hover:rotate-[5deg] hover:scale-125" src={image} alt="" />
        </div>
        <div className="relative h-[3.5rem] w-full rounded-b bg-white">
          <div className="absolute top-[-19px] left-[10%] w-[80%] py-1 font-semibold text-center text-xl text-white bg-[#0f5d9d]">{title}</div>
          <button className="absolute top-[40px] left-[30%] w-[40%] py-1 rounded-full outline outline-1 outline-[#1e2c39] bg-white" onClick={showServiceModal}>Read More</button>
        </div>
      </div>
    </>
  );
}

export default Service;
