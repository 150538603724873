import React, { useEffect } from 'react';
import BrowserTitleName from '../BrowserTitleName';
import PricingCard from './PricingCard';

function TaxPricing() {
  const taxPlanning = [
    "Generate an in-depth tax report based on your investments",
    "Get detailed guidance on investments that will help reduce your tax liabilities",
    "Receive expert guidance tailored to your specific needs",
    "All types of tax planning in one place (Individual, Business, NRI, Capital Gain)"
  ];

  const premiumHNI = [
    "Step by step tax planning strategy for single taxpayer",
    "Extensive overview on income sources (only for current financial year)",
    "Consolidated  tax report (Only for current financial year)",
    "Quarterly Advance Tax Calculation",
    "Personalized expert assistance virtually over Google Meet"
  ];

  const eliteHNI = [
    "Develop a Comprehensive & Family wide Tax Planning Strategy",
    "Consolidated Income Analysis & tax reports",
    "Advisory for effective Tax Planning for Long Term Perspective",
    "Provide user with Innovative Ideas & Strategy for Tax Saving",
    "Assisting in End to End Compliance Facilitation",
    "Provide Standard Operating System for Effective Asset Management",
    "Quarterly Advance Tax Calculation",
    "Personalized expert assistance virtually over Google Meet"
  ];

  // function hidePricingInfoModal() {
  //   window.document.getElementById("popup-price-info-modal").classList.add("hidden");
  //   window.document.getElementById("popup-price-info-modal").classList.remove("flex");
  // }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BrowserTitleName title="Tax Planning" />

      <div className="py-10 border-b bg-[#111827]">
        <div className="ms:container ms:mx-auto">
          <div className="flex justify-center mx-4">
            <div className="px-6 ms:px-16 py-20 flex flex-col items-center rounded-t-[16rem] sm:rounded-t-[8rem] bg-gradient-to-b from-indigo-900">
              <h1 className="mb-8 italic text-center font-semibold text-2xl ms:text-5xl text-white">What is Tax Planning?</h1>
              <div className="space-y-2 text-indigo-400">
                <div className="flex items-center">
                  <span className="px-3"><i className="fa-solid fa-magnifying-glass text-2xl"></i></span>
                  <span className="break-all">Involves analyzing your income, expenses investments and other financial activities</span>
                </div>
                <div className="w-full h-0.5 rounded-full bg-indigo-900"></div>
                <div className="flex items-center">
                  <span className="px-3"><i className="fa-solid fa-sheet-plastic text-2xl"></i></span>
                  <span className="break-all">Allows you to calculate income for the year and gives you an idea for tax deductions</span>
                </div>
                <div className="w-full h-0.5 rounded-full bg-indigo-800"></div>
                <div className="flex items-center">
                  <span className="px-3"><i className="fa-solid fa-sheet-plastic text-2xl"></i></span>
                  <span className="break-all">Tax strategies tailored to specific situations</span>
                </div>
                <div className="w-full h-0.5 rounded-full bg-indigo-800"></div>
                <div className="flex items-center">
                  <span className="px-3"><i className="fa-solid fa-magnifying-glass-dollar text-2xl"></i></span>
                  <span className="break-all">Identifies opportunities for reducing your tax bill</span>
                </div>
              </div>
            </div>
          </div>
          <div className="my-8 font-poppins font-semibold text-center text-3xl text-white">Plans depend on your Choice</div>
          <div className="px-4 sm:px-2 py-4 flex flex-wrap justify-center gap-6 gap-y-10">
            <div className="w-full sm:w-auto">
              <PricingCard
                title="Tax Planning"
                actualPrice={1499}
                discountPrice={999}
                conditions={taxPlanning}
              />
            </div>
            <div className="w-full sm:w-auto">
              <PricingCard
                title="Tax Planning for High Networth Individuals (HNI) - Premium Plan"
                actualPrice={14400}
                discountPrice={5760}
                conditions={premiumHNI}
              />
            </div>
            <div className="w-full sm:w-auto">
              <PricingCard
                title="Tax Planning for High Networth Individuals (HNI) - Elite Plan"
                actualPrice={30000}
                discountPrice={11999}
                conditions={eliteHNI}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TaxPricing;
