import { useState, useMemo, createContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import jwtDecode from "jwt-decode";
import {  sendSocketApproval } from "./socket";
import LoadingToast from "./component/toasts/LoadingToast";
import Base from "./component/unauthorizedPages/Base";
import { unauthorizedaxios } from "./axiosinit";
import AuthorizedBase from "./component/authorizedPages/AuthorizedBase";
import ErrorToast from "./component/toasts/ErrorToast";
import SuccessToast from "./component/toasts/SuccessToast";

export const AppProvide = createContext();

function App() {
  const navigate = useNavigate();
  const [is_authenticated, setIs_authenticated] = useState(false);
  const [is_loading, setIs_loading] = useState(false);
  const [directLogin, setDirectLogin] = useState(false);
  const [client_id, setClient_id] = useState('');
  const [successmsg, setSuccessmsg] = useState('');
  const [errormsg, setErrormsg] = useState('');
  const [userInfo, setUserInfo] = useState({
    name: '',
    email: '',
    phone: ''
  });

  useMemo(() => {
    unauthorizedaxios.post('/client/webfootprint');
    
  }, []);

  useMemo(() => {
    try {
      if (!localStorage.getItem('access_token')||jwtDecode(localStorage.getItem('refresh_token')).exp < Math.floor(Date.now() / 1000)) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        setIs_authenticated(false)
      }
      else{
        setIs_authenticated(true)
        const decode = jwtDecode(localStorage.getItem('refresh_token'));
        setClient_id(decode.sub)
        setUserInfo({ name: decode.name, email: decode.email, phone: decode.phone });
      }
    } catch (error) {
      
    }

    //sendSocketApproval();
  }, []);

  const data = {
    setIs_authenticated,
    setIs_loading,
    is_loading,
    setDirectLogin,
    directLogin,
    client_id, is_authenticated,
    setErrormsg,
    setSuccessmsg,
    userInfo, setUserInfo,setClient_id
  };

  if (is_authenticated) {
    return (
      <>
        <AppProvide.Provider value={data}>
          <ErrorToast message={errormsg} setMessage={setErrormsg} />
          <SuccessToast message={successmsg} setMessage={setSuccessmsg} />
          <LoadingToast />
          <AuthorizedBase />
        </AppProvide.Provider>
      </>
    );
  }

  return (
    <>
      <AppProvide.Provider value={data}>
        
        <ErrorToast message={errormsg} setMessage={setErrormsg} />
        <SuccessToast message={successmsg} setMessage={setSuccessmsg} />
        <LoadingToast />
        <Base />
      </AppProvide.Provider>
    </>
  );
}

export default App;
