import React, { useContext, useEffect, useState } from 'react'
import { unauthorizedaxios } from "../../axiosinit";
import { AppProvide } from "../../App";
import { useNavigate } from "react-router-dom";
import { BaseProvide } from "../unauthorizedPages/Base";
import { phoneValidate } from "../../phoneValidation";

const PhoneModal = ({ close }) => {
  const Appcontext = useContext(AppProvide);
  const Basecontext = useContext(BaseProvide);
  const navigate = useNavigate();
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  const sendOTP = (email) => {
    unauthorizedaxios.post('/auth/client_verify', { email: email })
      .then((res) => {
        //console.log(res)
      })
  }

  const submitPhoneNumber = () => {
    Appcontext.setIs_loading(true)
    unauthorizedaxios.post(`/auth/is_clientexists/phone=${Basecontext.phone}`)
      .then((res) => {
        if (JSON.parse(res.data['exist_status'])) {
          Appcontext.setDirectLogin(true)
          Basecontext.setEmail(res.data['email'])
          Basecontext.navigate("/otp-verify")
          sendOTP(res.data['email'])
          Appcontext.setIs_loading(false)
        }
        else {
          Basecontext.setClient_id(res.data['client_id'])
          Basecontext.setEmail('')
          Appcontext.setDirectLogin(false)
          Appcontext.setIs_loading(false)
          Basecontext.navigate("/signup")
        }
      })
      .catch((err) => {
        if (err) {
          Appcontext.setIs_loading(false)
        }
      })

  }

  const handelGetStarted = e => {
    e.preventDefault();

    if (Appcontext.is_authenticated) {
      navigate("/")
    } else {
      if (Basecontext.phone) {
        submitPhoneNumber();
      }
    }
  }

  useEffect(() => {
    if (Basecontext.phone !== '') {
      setIsPhoneValid(phoneValidate(Basecontext.phone));
    }
  }, [Basecontext.phone]);

  return (
    <div data-aos="fade-down" id="popup-phone-modal" tabIndex={-1} className="hidden form-font fixed top-0 left-0 right-0 z-50 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal h-full justify-center bg-opacity-50 items-center bg-gray-900">
      <div className="relative w-full h-full max-w-md md:h-auto">
        <div id="add-category-modal" className="relative bg-white rounded-lg shadow">
          <div className="px-4 py-5 flex justify-between items-center">
            <div className="font-sans text-xl font-semibold text-gray-800">Enter Phone Number</div>
            <button className="px-2 py-2" >
              <i className="items-center fa-solid fa-xmark text-2xl text-slate-400 hover:text-slate-600" onClick={close} />
            </button>
          </div>
          <hr />
          <form onSubmit={handelGetStarted}>
            <div className="px-4 py-5">
              <div className="relative z-0 w-full group">
                <input placeholder=' ' type="text" name="floating_first_name" id="floating_first_name" className="text-gray-500 font-medium text-lg block py-2.5 px-0 w-full bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" value={Basecontext.phone} onChange={(e) => Basecontext.setPhone(e.target.value)} required />
                <label htmlFor="floating_first_name" className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Phone
                  Number</label>
                {
                  !isPhoneValid && <span className="font-roboto text-sm text-red-600">Phone Number must have 10 digits only</span>
                }
              </div>
            </div>
            <hr />
            <div className="px-4 py-5 flex justify-end space-x-2">
              <button type="button" className="px-4 py-2 rounded text-white bg-gray-600 hover:bg-gray-700 text-[13px] sm:text-base" onClick={close}>Close</button>
              <button type="submit" style={{ cursor: (Basecontext.phone === '' || !isPhoneValid) ? "not-allowed" : "pointer" }} className="px-4 py-2 rounded text-white bg-blue-600 hover:bg-blue-700 text-[13px] sm:text-base" disabled={!isPhoneValid}>Start Filing</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default PhoneModal;
