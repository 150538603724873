import { authorizedaxios } from "./axiosinit";

export const collectNewToken = (userID) => {
    const refresh_token = localStorage.getItem('refresh_token');
    authorizedaxios(refresh_token).get(`/auth/collect_newToken/user_id=${userID}&user_type=client`)
        .then((res) => {
            if (res.status === 200) {
                localStorage.setItem("access_token", res.data['access_token']);
                localStorage.setItem("refresh_token", res.data['refresh_token']);
            }
        })
        .catch((err) => {
            console.log(err)
        })
    return true
}