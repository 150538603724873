import React, { useContext } from "react";
import PropTypes from "prop-types";
import PhoneModal from "../unauthorizedPages/PhoneModal";
import { AppProvide } from "../../App";
import { formattedPrice } from "../../formattedPrice";
import "./pricing.css";

function PricingCard(props) {
  const Appcontext = useContext(AppProvide);

  function showPhoneModal() {
    window.document.getElementById("popup-phone-modal").classList.add("flex");
    window.document.getElementById("popup-phone-modal").classList.remove("hidden");
  }

  function hidePhoneModal() {
    window.document.getElementById("popup-phone-modal").classList.add("hidden");
    window.document.getElementById("popup-phone-modal").classList.remove("flex");
  }

  return (
    <>
      {
        !Appcontext.is_authenticated &&
        <PhoneModal close={hidePhoneModal} />
      }

      <div className="sm:w-[450px] md:w-[350px] xl:w-[400px] glowing-card h-full p-0.5 rounded duration-150 hover:scale-105 bg-gradient-to-br from-blue-400 via-purple-500 to-blue-600">
        <div className="font-roboto h-full px-6 py-4 flex flex-col justify-between rounded text-white bg-[#111827]">
          <div className="">
            <div className="">
              <div className="inline-flex p-2 rounded bg-[#c9dcf0]">
                <i className="fa-solid fa-wallet text-[#175a79]"></i>
              </div>
            </div>
            <div className="mt-2 text-2xl font-medium">{props.title}</div>
            <div className="py-2 flex">
              <div className="">
                <h1 className="text-5xl font-semibold text-green-400">₹{formattedPrice(props.discountPrice)}</h1>
                <h1 className="line-through text-5xl font-semibold text-orange-400">₹{formattedPrice(props.actualPrice)}</h1>
              </div>
              <span className="self-end"> / Year</span>
            </div>
            <span className="text-sm">Inclusive Of Taxes</span>
            {props.suitedFor && <div className="mt-8 uppercase text-sm font-medium text-green-500">SUITED FOR:</div>}
            {
              props.conditions.map((condition, id) =>
                <ul key={id} className="mt-4 px-2 space-y-2">
                  <li className="flex items-center space-x-2">
                    <span><i className="fa-solid fa-star text-2xl text-[#ffbb00]"></i></span>
                    <span className="sm:w-[300px]">{condition}</span>
                  </li>
                </ul>
              )
            }
            {/* <span className="mt-4 px-2 flex items-center space-x-2">
              <span><i class="fa-solid fa-circle-chevron-down text-2xl text-blue-500"></i></span>
              {props.readMoreComponent}
            </span> */}
          </div>
          <div className="">
            <div className="mx-2 mt-12 mb-4">
              {
                (!Appcontext.is_authenticated) &&
                <button className="w-full py-2.5 font-medium rounded text-black bg-gradient-to-l from-cyan-400 to-blue-500" onClick={showPhoneModal}>Get Started Now</button>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

PricingCard.propTypes = {
  title: PropTypes.string,
  actualPrice: PropTypes.number,
  discountPrice: PropTypes.number,
  suitedFor: PropTypes.bool,
  conditions: PropTypes.array,
  readMoreComponent: PropTypes.element
};

export default PricingCard;
