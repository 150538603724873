import React from 'react';
import calc from "../../assets/calc/calc2.png";
import "./calculators.css";

function Calculator({ id, name, title, component, maxHeight, toggleCalculator }) {
  return (
    <div className="calculators select-none">
      {/* <div className="px-10 py-4 flex justify-between items-center" onClick={() => toggleCalculator(id, maxHeight)}> */}
      <div className="px-10 py-4 flex justify-between items-center" onClick={() => console.log("Toggle disabled")} title={title}>
        <div className="font-poppins font-semibold text-2xl flex items-center space-x-2">
          <span>{name}</span>
          <span><img className="w-8 rounded" src={calc} alt="" /></span>
        </div>
        <div className="hidden">
          <button>
            <i className="fa-solid fa-angle-right duration-150"></i>
          </button>
        </div>
      </div>
      {/* <div className="component overflow-hidden"> */}
      <div className="select-text">
        {component}
      </div>
    </div>
  );
}

export default Calculator;
