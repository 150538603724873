import React, { useContext, useEffect, useState } from 'react';
import { AppProvide } from '../../App';
import { unauthorizedaxios } from '../../axiosinit';
import { phoneValidate } from '../../phoneValidation';
import BrowserTitleName from "../BrowserTitleName";
import { BaseProvide } from '../unauthorizedPages/Base';

function Notices() {
  const Basecontext = useContext(BaseProvide);
  const Appcontext = useContext(AppProvide);
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  const sendOTP = (email) => {
    unauthorizedaxios.post('/auth/client_verify', { email: email })
      .then((res) => {
        console.log(res)
      })
  }

  const submitPhoneNumber = e => {
    e.preventDefault();
    if (Basecontext?.phone) {
      Appcontext.setIs_loading(true)
      unauthorizedaxios.post(`/auth/is_clientexists/phone=${Basecontext.phone}`)
        .then((res) => {
          console.log(res)
          if (JSON.parse(res.data['exist_status'])) {
            Appcontext.setDirectLogin(true)
            Basecontext.setEmail(res.data['email'])
            Basecontext.navigate("/otp-verify")
            sendOTP(res.data['email'])
            Appcontext.setIs_loading(false)
          }
          else {
            Basecontext.setClient_id(res.data['client_id'])
            Basecontext.setEmail('')
            Appcontext.setDirectLogin(false)
            Appcontext.setIs_loading(false)
            Basecontext.navigate("/signup")
          }
        })
        .catch((err) => {
          if (err) {
            Appcontext.setIs_loading(false)
          }
        })

    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    if (Basecontext?.phone !== '') {
      setIsPhoneValid(phoneValidate(Basecontext?.phone));
    }
  }, [Basecontext?.phone]);

  return (
    <>
      <BrowserTitleName title="Notices" />

      <div className="mb-[0.05rem] bg-[#111827]">
        <div className="mx-4">
          <div className="sm:container sm:mx-auto">
            <div className="flex justify-center items-center">
              <div className="py-20">
                <div className="font-bold text-4xl text-yellow-200">
                  <h1>If Received Income Tax</h1>
                  <h1>Notice?</h1>
                  <h1>Let TaxSuvidhaa assist you</h1>
                </div>
                <div className="font-roboto font-medium text-lg mt-10 w-[70%] text-slate-400">Assists in preparing replies for any notices received from the Income Tax Department.</div>
                {!Appcontext.is_authenticated&&<form onSubmit={submitPhoneNumber} className="mt-10 flex flex-col md:flex-row md:space-x-3 md:space-y-0 space-y-3 font-roboto text-xl md:text-lg">
                  <div className="w-full md:w-[60%] rounded">
                    {
                      !isPhoneValid &&
                      <span className="mt-2 font-roboto font-medium text-red-500">Phone Number must be of 10 digits*</span>
                    }
                    <input value={Basecontext?.phone} onChange={(e) => Basecontext.setPhone(e.target.value)} className="px-3 py-3 w-full rounded focus:outline-none text-red-500" type="text" name="" id="" placeholder="Enter your mobile number" />
                  </div>
                  <button style={{ cursor: (Basecontext?.phone === '' || !isPhoneValid) ? "not-allowed" : "pointer" }} className="px-6 py-3 self-end rounded duration-200 text-white hover:bg-blue-500 bg-red-500" disabled={!isPhoneValid}>Start Filing</button>
                </form>}
                <div className="mt-10 flex flex-col">
                  <span className="mb-10 font-roboto font-semibold text-center text-4xl text-blue-400">What You'll Get</span>
                  <ul className="font-poppins px-10 flex flex-col gap-4 justify-center text-white">
                    <li className="flex space-x-2">
                      <div><i className="fa-solid fa-circle-check text-xl text-slate-400"></i></div>
                      <div className="space-y-2">
                        <div className="text-xl text-green-400">Relax</div>
                        <div className="text-slate-400">Sign in & Relax</div>
                      </div>
                    </li>
                    <li className="flex space-x-2">
                      <div><i className="fa-solid fa-circle-check text-xl text-slate-400"></i></div>
                      <div className="space-y-2">
                        <div className="text-xl text-green-400">Chat Support</div>
                        <div className="text-slate-400">More status via Call or WhatsApp / Mail</div>
                      </div>
                    </li>
                    <li className="flex space-x-2">
                      <div><i className="fa-solid fa-circle-check text-xl text-slate-400"></i></div>
                      <div className="space-y-2">
                        <div className="text-xl text-green-400">ITR Notices Assistance</div>
                        <div className="text-slate-400">End to End assistance for all ITR notices</div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Notices;
